var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as ReactEmail from '@react-email/components';
import parse, { domToReact, Element } from 'html-react-parser';
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import parseCSS from 'style-to-js';
export function replaceTemplateTags(htmlString, replacements, options) {
    if (options === void 0) { options = {}; }
    // Default options
    var defaultOptions = {
        keepOriginalIfMissing: true, // if true, keeps original tag if no replacement found
        removeIfMissing: false, // if true, removes the tag if no replacement found
        logMissing: false, // if true, logs missing replacements to console
    };
    var config = __assign(__assign({}, defaultOptions), options);
    // Find all template tags in the HTML string
    var templateTagRegex = /<template-[^>\s]+[^>]*>.*?<\/template-[^>\s]+>/g;
    var matches = Array.from(htmlString.matchAll(templateTagRegex));
    // Process the HTML string
    return matches.reduce(function (html, match) {
        // Extract the tag name from the full match
        var tagMatch = match[0].match(/<(template-[^>\s]+)/);
        if (!tagMatch)
            return html;
        var tagName = tagMatch[1];
        var hasReplacement = tagName in replacements;
        if (!hasReplacement) {
            if (config.logMissing) {
                console.warn("No replacement found for tag: ".concat(tagName));
            }
            if (config.removeIfMissing) {
                // Remove the tag completely
                return html.replace(match[0], '');
            }
            if (config.keepOriginalIfMissing) {
                // Keep the original tag (default behavior)
                return html;
            }
            // If both removeIfMissing and keepOriginalIfMissing are false,
            // replace with empty string
            return html.replace(match[0], '');
        }
        // Replace the tag with its replacement
        var regex = new RegExp("<".concat(tagName, "[^>]*>.*?</").concat(tagName, ">"), 'g');
        return html.replace(regex, replacements[tagName]);
    }, htmlString);
}
export var reactEmailCreatedWith = function () {
    return "<react-email-section style=\"text-align:center;padding:14px 48px 40px\"><react-email-text style=\"display:inline;text-align:center;font-size: 14px; line-height: 24px;color:#000000\">Powered by</react-email-text><react-email-link target=\"_blank\" rel=\"noreferrer\" href=\"https://mailberry.ai/?utm_source=Email&utm_medium=Mailberry&utm_campaign=CustomersAreFrom\"><img width=\"75\" height=\"18\" style=\"vertical-align: text-bottom; display: inline-block; outline:none; margin-left: 5px\" src=\"http://d1q3o19jn6nxkf.cloudfront.net/email/mailberry-logo.png\" alt=\"Mailberry logo\"></img></react-email-link><span style=\"display: none\">".concat(Date.now(), "</span></react-email-section>\n");
};
export var reactEmailFooter = function (businessAddress, isCompanyEmail) {
    var bussinessAddress = "<react-email-section style=\"padding:0 50px\" ><react-email-text style=\"text-decoration:underline;text-align:center;font-size:14px;line-heigth:24px;margin:8px 0;padding-top:32px;color: #000\">".concat(businessAddress, "</react-email-text></react-email-section>");
    var unsubscribeButton = "<react-email-section style=\"padding:0 50px\" ><react-email-text style=\"text-align:center;font-size: 14px; line-height: 24px;margin:8px 0;padding-bottom:32px;color: #000\";white-space: nowrap;> No longer want to receive these emails? <react-email-link href=\"{{UNSUBSCRIBE_LINK}}\" style=\"font-size: 14px; line-height: 24px; text-decoration: underline; text-align: center;\">Unsubscribe</react-email-link></react-email-text></react-email-section> ";
    var footer = isCompanyEmail ? bussinessAddress : (bussinessAddress + unsubscribeButton);
    return cleanHtmlContent(footer);
};
export var reactEmailPreheader = function (preheaderText) { return "<react-email-preview>".concat(preheaderText, "</react-email-preview>"); };
var tipTapTagsReactEmailComponentsMap = {
    'react-email-section': ReactEmail.Section,
    'react-email-section-featured-box': ReactEmail.Section,
    'react-email-text': ReactEmail.Text,
    'react-email-body': ReactEmail.Body,
    'react-email-head': ReactEmail.Head,
    'react-email-heading': ReactEmail.Heading,
    'react-email-container': ReactEmail.Container,
    'react-email-html': ReactEmail.Html,
    'react-email-image': ReactEmail.Img,
    'react-email-button': ReactEmail.Button,
    'react-email-hr': ReactEmail.Hr,
    'react-email-preview': ReactEmail.Preview,
    'react-email-link': ReactEmail.Link,
    'react-email-row': ReactEmail.Row,
    'react-email-column': ReactEmail.Column,
};
var selfClosingTags = ['hr', 'img', 'br', 'input', 'meta', 'link'];
export var convertHTMLToReactComponent = function (htmlString) {
    var parserOptions = {
        replace: function (domNode) {
            if (domNode instanceof Element) {
                var Component = tipTapTagsReactEmailComponentsMap[domNode.name];
                if (Component) {
                    var reactAttrs_1 = {};
                    var domAttrs_1 = domNode.attribs;
                    // Copy all attributes except style
                    Object.keys(domAttrs_1).forEach(function (key) {
                        if (key !== 'style') {
                            reactAttrs_1[key] = domAttrs_1[key];
                        }
                    });
                    // Handle style separately
                    if (domAttrs_1.style) {
                        var styleObject = parseCSS(domAttrs_1.style);
                        if (styleObject) {
                            reactAttrs_1.style = styleObject;
                        }
                    }
                    var isSelfClosingTag = selfClosingTags.includes(domNode.name);
                    if (domNode.name === 'react-email-head') {
                        var styleElement = React.createElement('style', null, mobileStyles);
                        var existingChildren = domNode.children && domNode.children.length > 0
                            ? domToReact(domNode.children, parserOptions)
                            : null;
                        return React.createElement(Component, reactAttrs_1, [styleElement, existingChildren]);
                    }
                    return React.createElement(Component, reactAttrs_1, isSelfClosingTag
                        ? undefined
                        : domNode.children && domNode.children.length > 0
                            ? domToReact(domNode.children, parserOptions)
                            : null);
                }
            }
            return domNode;
        },
    };
    var parsedContent = parse(htmlString, parserOptions);
    return React.isValidElement(parsedContent)
        ? parsedContent
        : React.createElement(React.Fragment, null, parsedContent);
};
export var generateEmailCompatibleHTML = function (htmlString) { return __awaiter(void 0, void 0, Promise, function () {
    var reactComponent, jsxString;
    return __generator(this, function (_a) {
        reactComponent = (function () { return convertHTMLToReactComponent(htmlString); })();
        jsxString = ReactDOMServer.renderToStaticMarkup(reactComponent);
        console.log({ staticMarkup: jsxString });
        return [2 /*return*/, ReactEmail.render(reactComponent, {
                pretty: false,
            })];
    });
}); };
export var mobileStyles = '@media (max-width: 480px) { .document-body { padding: 0px !important; } .document-container { border: none !important; border-radius: 0px !important; border-bottom-left-radius: 10px !important; border-bottom-right-radius: 10px !important; }  .node-section { padding-left: 20px !important; padding-right: 20px !important;}}';
/**
 * Cleans HTML content by removing unnecessary whitespace between tags and trimming.
 * @param html The HTML content to clean
 * @returns The cleaned HTML content
 */
export function cleanHtmlContent(html) {
    return html
        .replace(/>\s+</g, '><') // Remove whitespace between tags
        .replace(/\s+$/g, '') // Remove trailing whitespace
        .trim(); // Remove leading/trailing whitespace
}
