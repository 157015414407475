var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { IconArrowsDoubleSeNw } from '@tabler/icons-react';
import { nodeInputRule } from '@tiptap/core';
import Image from '@tiptap/extension-image';
import { ReactNodeViewRenderer } from '@tiptap/react';
import React from 'react';
import styleObjectToCss from 'style-object-to-css-string';
import ImageResizeView from './ImageResizeView';
export var inputRegex = /<mailberryimage\b[^>]*>/;
export var ReactEmailImage = Image.extend({
    name: 'imageResize',
    addOptions: function () {
        return {
            inline: false,
            allowBase64: false,
            HTMLAttributes: {},
            resizeIcon: (React.createElement(React.Fragment, null,
                React.createElement(IconArrowsDoubleSeNw, null))),
            useFigure: true,
        };
    },
    addAttributes: function () {
        var _a;
        return __assign(__assign({}, (_a = this.parent) === null || _a === void 0 ? void 0 : _a.call(this)), { width: {
                default: '758',
                renderHTML: function (attributes) {
                    return {
                        width: attributes.width,
                    };
                },
            }, height: {
                default: 'auto',
                renderHTML: function (attributes) {
                    return {
                        height: attributes.height,
                    };
                },
            }, isDraggable: {
                default: true,
                renderHTML: function (attributes) {
                    return {};
                },
            }, caption: {
                default: true,
                renderHTML: function (attributes) {
                    return {
                        caption: attributes.title,
                    };
                },
            }, linkurl: {
                default: '',
                renderHTML: function (attributes) {
                    return {
                        linkurl: attributes.linkurl,
                    };
                },
            }, align: {
                default: 'center',
                renderHTML: function (attributes) {
                    return {
                        align: attributes.align,
                    };
                },
                parseHTML: function (element) {
                    var _a;
                    var style = element.parentNode.getAttribute('style');
                    var justifyContent = (_a = style === null || style === void 0 ? void 0 : style.match(/justify-content:\s*([^;]+)/)) === null || _a === void 0 ? void 0 : _a[1];
                    return justifyContent;
                },
            }, 'data-border': {
                default: 'false',
                renderHTML: function (attributes) {
                    return {
                        'data-border': attributes['data-border'],
                    };
                },
            }, 'data-roundedcorners': {
                default: 'false',
                renderHTML: function (attributes) {
                    return {
                        'data-roundedcorners': attributes['data-roundedcorners'],
                    };
                },
            }, 'data-uploaded-from': {
                default: 'DEVICE',
                renderHTML: function (attributes) {
                    return {
                        'data-uploaded-from': attributes['data-uploaded-from'],
                    };
                },
            }, 'data-author': {
                default: null,
                renderHTML: function (attributes) {
                    return {
                        'data-author': attributes['data-author'],
                    };
                },
            }, 'data-author-profile': {
                default: null,
                renderHTML: function (attributes) {
                    return {
                        'data-author-profile': attributes['data-author-profile'],
                    };
                },
            }, 'data-section-styles': {
                default: styleObjectToCss({
                    padding: '12px 0px',
                }),
            } });
    },
    parseHTML: function () {
        return [
            {
                tag: 'react-email-image',
            },
        ];
    },
    renderHTML: function (_a) {
        var HTMLAttributes = _a.HTMLAttributes;
        var imageStyles = { maxWidth: '100%' };
        if (String(HTMLAttributes['data-roundedcorners']) === 'true') {
            imageStyles.borderRadius = '12px';
        }
        if (String(HTMLAttributes['data-border']) === 'true') {
            imageStyles.border = '2px solid black';
        }
        if (HTMLAttributes['align'] === 'center') {
            imageStyles.margin = 'auto';
        }
        else if (HTMLAttributes['align'] === 'left') {
            imageStyles.marginRight = 'auto';
        }
        else if (HTMLAttributes['align'] === 'right') {
            imageStyles.marginLeft = 'auto';
        }
        var imageAtributtes = {
            src: HTMLAttributes.src,
            width: HTMLAttributes.width,
            height: 'auto',
            alt: HTMLAttributes.alt,
            'data-border': HTMLAttributes['data-border'],
            'data-roundedcorners': HTMLAttributes['data-roundedcorners'],
            'data-uploaded-from': HTMLAttributes['data-uploaded-from'],
            'data-author': HTMLAttributes['data-author'],
            'data-author-profile': HTMLAttributes['data-author-profile'],
            style: styleObjectToCss(imageStyles),
        };
        var sectionAttributes = { style: HTMLAttributes['data-section-styles'] };
        if (HTMLAttributes.linkurl) {
            return ['react-email-section', sectionAttributes, ['react-email-link', { href: HTMLAttributes.linkurl }, ['react-email-image', imageAtributtes]]];
        }
        return ['react-email-section', sectionAttributes, ['react-email-image', imageAtributtes]];
    },
    addNodeView: function () {
        return ReactNodeViewRenderer(ImageResizeView);
    },
    addInputRules: function () {
        return [
            nodeInputRule({
                find: inputRegex,
                type: this.type,
                getAttributes: function (match) {
                    var alt = match[2], src = match[3], title = match[4], height = match[5], width = match[6], isDraggable = match[7], align = match[8];
                    return {
                        src: src,
                        alt: alt,
                        title: title,
                        height: height,
                        width: width,
                        isDraggable: isDraggable,
                        align: align,
                        'data-border': match['data-border'],
                        'data-roundedcorners': match['data-roundedcorners'],
                        'data-uploaded-from': match['data-uploaded-from'],
                        'data-author': match['data-author'],
                        'data-author-profile': match['data-author-profile'],
                    };
                },
            }),
        ];
    },
});
