import { Link } from '@tiptap/extension-link';
import { TextAlign } from '@tiptap/extension-text-align';
import { Underline } from '@tiptap/extension-underline';
import { Editor, EditorContent, useEditor } from '@tiptap/react';

import { Blockquote } from '@tiptap/extension-blockquote';
import { Bold } from '@tiptap/extension-bold';
import { Color } from '@tiptap/extension-color';
import { Dropcursor } from '@tiptap/extension-dropcursor';
import { HardBreak } from '@tiptap/extension-hard-break';
import { History } from '@tiptap/extension-history';
import { Italic } from '@tiptap/extension-italic';
import Placeholder from '@tiptap/extension-placeholder';
import { Strike } from '@tiptap/extension-strike';
import { Text } from '@tiptap/extension-text';
import TextStyle from '@tiptap/extension-text-style';

import { forwardRef, useEffect, useRef, useState } from 'react';

import FontFamily from '@tiptap/extension-font-family';

import { ReactEmailBulletList } from '@/sharedTypes/reactComponents/TiptapReactEmail/Custom/BulletList/ReactEmailBulletList';
import { ReactEmailButton } from '@/sharedTypes/reactComponents/TiptapReactEmail/Custom/Button/ReactEmailButton';
import { ReactEmailDivider } from '@/sharedTypes/reactComponents/TiptapReactEmail/Custom/Divider/ReactEmailDivider';
import { ReactEmailDocument } from '@/sharedTypes/reactComponents/TiptapReactEmail/Custom/Document/ReactEmailDocument';
import { ReactEmailFeaturedBox } from '@/sharedTypes/reactComponents/TiptapReactEmail/Custom/FeaturedBox/ReactEmailFeaturedBox';
import { ReactEmailHeading } from '@/sharedTypes/reactComponents/TiptapReactEmail/Custom/Heading/ReactEmailHeading';
import { ReactEmailImage } from '@/sharedTypes/reactComponents/TiptapReactEmail/Custom/ImageResize/ReactEmailImage';
import { ReactEmailListItem } from '@/sharedTypes/reactComponents/TiptapReactEmail/Custom/Listitem/ReactEmailListItem';
import { ReactEmailParagraph } from '@/sharedTypes/reactComponents/TiptapReactEmail/Custom/Paragraph/ReactEmailParagraph';

// Function to get XPath of an element
function getXPath(element: Element) {
  if (element.id !== '') {
    return '//*[@id="' + element.id + '"]';
  }

  if (element === document.body) {
    return '/html/body';
  }

  let ix = 0;
  const siblings = element.parentNode.childNodes;

  for (let i = 0; i < siblings.length; i++) {
    const sibling = siblings[i];
    if (sibling === element) {
      const pathIndex = ix + 1;
      const path = getXPath(element.parentNode as Element) + '/' + element.tagName.toLowerCase() + '[' + pathIndex + ']';
      return path;
    }

    if (sibling.nodeType === 1 && (sibling as Element).tagName === element.tagName) {
      ix++;
    }
  }
}

// Add comment count indicators to elements
export function addCommentCounters(comments: Record<string, any>, ref: React.RefObject<HTMLDivElement>) {
  // Remove any existing counters first
  ref.current.querySelectorAll('.comment-counter').forEach(el => el.remove());

  // Add counters for each xpath that has comments
  Object.entries(comments).forEach(([xpath, commentsList]) => {
    try {
      const element = document.evaluate(xpath, document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue as Element;

      if (element) {
        const rect = element.getBoundingClientRect();

        if (rect.top <= 68) return;

        const counter = document.createElement('div');
        counter.className = 'comment-counter';
        counter.textContent = commentsList.length;
        counter.style.cssText = `
          font-family: __PT_Sans_94ebfd, __PT_Sans_Fallback_94ebfd;
          position: absolute;
          top: ${rect.top}px;
          left: ${rect.right - 40}px;
          background-color: #3B82F6;
          color: white;
          z-index: 1;
          pointer-events: none;
          height: 30px;
          max-height: 30px;
          padding: 0px 16px;
          border-radius: 4px;
          line-height: 30px;
          font-size: 16px;
        `;

        ref.current.appendChild(counter);
      }
    } catch (e) {
      console.error('Error adding counter for xpath:', xpath, e);
    }
  });
}

declare global {
  interface Window {
    global_editor: Editor;
  }
}

interface TiptapReactEmailEditorProps {
  defaultValue: string;
  isMobile: boolean;
  onLoad?: (a: any) => () => void;
}

const TiptapReactEmailEditor = forwardRef<HTMLDivElement, TiptapReactEmailEditorProps>(({ defaultValue, isMobile = false, onLoad }, tiptapRef) => {
  const [isEditorReady, setIsEditorReady] = useState(false);
  const commentsRef = useRef<Record<string, any>>({});

  const editor = useEditor({
    autofocus: true,
    extensions: [
      Blockquote,
      Bold,
      HardBreak,
      History,
      Italic,
      TextStyle,
      Color.configure({
        types: ['textStyle'],
      }),
      Strike,
      // to modify the placeholder text, edit the content in .ProseMirror div.is-editor-empty
      Placeholder,
      Underline.configure({
        HTMLAttributes: {
          // class: 'my-custom-class',
        },
      }),
      Link.configure({
        autolink: false,
        openOnClick: false,
      }),
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
      Dropcursor.configure({
        width: 4,
        // The color is set in .prosemirror-dropcursor-block
      }),
      Text,
      ReactEmailDocument.configure({
        isMobile,
      }),
      ReactEmailParagraph,
      ReactEmailListItem,
      ReactEmailBulletList,
      ReactEmailHeading,
      ReactEmailDivider,
      ReactEmailImage,
      ReactEmailButton,
      ReactEmailFeaturedBox,

      FontFamily.configure({
        types: ['textStyle'],
      }),
    ],
    content: defaultValue,
    editable: false,
    onCreate: () => {
      // Editor is ready
      setIsEditorReady(true);
    },
  }, [isMobile]);

  const handleIframeLoad = (comments: Record<string, any>) => {
    if (!tiptapRef || !('current' in tiptapRef) || !tiptapRef.current) return;

    commentsRef.current = comments;

    if (isEditorReady) {
      addCommentCounters(comments, tiptapRef);

      // Click handler
      tiptapRef.current.addEventListener('click', e => {
        const target = e.target as HTMLElement;
        const rect = target.getBoundingClientRect();
        const xpath = getXPath(target);

        const elementInfo = {
          tagName: target.tagName,
          className: target.className,
          id: target.id,
          text: target.textContent?.slice(0, 50) || '',
          xpath: xpath,
        };

        const position = {
          x: rect.left + rect.width,
          y: rect.top,
        };

        window.postMessage({
          type: 'elementClicked',
          elementInfo,
          position,
          xpath,
        }, '*');

        e.preventDefault();
      });
    }
  };

  // exchange messages with layout scroll
  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      const { type } = event.data;
      if (type === 'layoutScroll') {
        requestAnimationFrame(() => {
          // Check if tiptapRef is a RefObject (not a function)
          if (tiptapRef && typeof tiptapRef !== 'function' && tiptapRef.current) {
            // Create a proper RefObject for addCommentCounters
            const refObject: React.RefObject<HTMLDivElement> = {
              current: tiptapRef.current,
            };
            addCommentCounters(commentsRef.current, refObject);
          }
        });
      }
    };
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  useEffect(() => {
    if (editor) {
      editor.storage.isMobile = isMobile;
    }

    if (editor && tiptapRef && typeof tiptapRef === 'object' && tiptapRef.current) {
      onLoad && onLoad(handleIframeLoad)();
    }
  }, [editor, isMobile, isEditorReady]);

  if (!editor) return null;

  return <EditorContent editor={editor} ref={tiptapRef} />;
});

export default TiptapReactEmailEditor;
