import { BehaviorRule, FieldRule, SEGMENT_ORIGIN } from '@/sharedTypes';
import SegmentCompositionSentence from '@components/screens/Segments/Options/SegmentCompositionSentence';
import { Badge, Group, Stack, Text, Title } from '@mantine/core';
import { useHover } from '@mantine/hooks';
import { ReactElement } from 'react';

const SegmentOriginBadge = ({ origin }) => {
  const originMap = {
    [SEGMENT_ORIGIN.DYNAMIC]: { color: '#ED5959', text: 'segment' },
    [SEGMENT_ORIGIN.FILE]: { color: '#55C8FA', text: 'file' },
    [SEGMENT_ORIGIN.FORM]: { color: '#EFBA51', text: 'form' },
    [SEGMENT_ORIGIN.MANUALLY]: { color: '#70C56E', text: 'manually' },
  };

  return <Badge mb={4} size='md' color={originMap[origin].color} variant='outline'>{originMap[origin].text}</Badge>;
};

interface MBAudienceOptionProps {
  selected: boolean;
  title: string;
  onClick: () => void;
  subscriberCount: string | number | ReactElement;
  averageOpenRate: number;
  fieldRule?: FieldRule[];
  behaviorRule?: BehaviorRule[];
  parentSegments?: { id: string; name: string; origin: SEGMENT_ORIGIN; }[];
  origin?: SEGMENT_ORIGIN;
}

export function MBAudienceOption({ selected, title, onClick = () => {}, subscriberCount, averageOpenRate, fieldRule, behaviorRule, parentSegments, origin }: MBAudienceOptionProps) {
  const { hovered, ref } = useHover();

  return (
    <Group
      maw={640}
      w={'100%'}
      pos={'relative'}
      ref={ref}
      gap={0}
      style={{
        border: '1px solid #DBDBDB',
        borderRadius: ' 8px',
        cursor: 'pointer',
        '&:hover': {
          boxShadow: '0px 0px 24px rgba(0, 0, 0, 0.1)',
        },
      }}
      onClick={onClick}
    >
      <Group
        gap={16}
        px={32}
        py={24}
        w='100%'
        justify='space-between'
      >
        <Stack gap={8}>
          {origin && <SegmentOriginBadge key={title + '_' + origin} origin={origin} />}

          <Title
            order={2}
            style={{
              textOverflow: 'ellipsis',
              maxHeight: 32,
              userSelect: 'none',
              whiteSpace: 'nowrap',
            }}
          >
            {title}
          </Title>
          {origin === SEGMENT_ORIGIN.DYNAMIC
            && (
              <>
                <SegmentCompositionSentence behaviorRule={behaviorRule} fieldRule={fieldRule} parentSegments={parentSegments} />
              </>
            )}
        </Stack>
        <Group gap={16}>
          <Stack gap={0} h={48} align='center'>
            <Text fz={12} fw={400} lh={'16px'}>
              Subscribers
            </Text>
            <Title order={2}>{subscriberCount}</Title>
          </Stack>
          <Stack gap={0} h={48} align='center'>
            <Text fz={12} fw={400} lh={'16px'}>
              Avg Open Rate
            </Text>
            <Title order={2}>{averageOpenRate}%</Title>
          </Stack>
        </Group>
      </Group>
    </Group>
  );
}
