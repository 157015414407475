import { demoEmail } from '@/lib/constants';
import AppState from '@/services/state/AppState';
import { formatDateToUserTimezone } from '@/sharedTypes/utils';
import { getClientTimezone } from '@lib/utils';
import MBEmptySubscribers from '@mailberry/Empty/MBEmptySubscribers';
import MailberryTable from '@mailberry/MailberryTable';
import { MBTableSkeleton } from '@mailberry/Table/Skeleton/MBTableSkeleton';
import { Box, Group, Pagination, ScrollArea, Space, Title } from '@mantine/core';
import { useExportDeliverySubscribers, useGetDeliverySubscribers, useGetDeliverySubscribersCount } from '@services/api/delivery';
import { usePagination } from '@services/hooks/usePagination';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

export const RecipientsWrapper = ({ delivery, isLoadingDraft }) => {
  const exportDeliverySubscribers = useExportDeliverySubscribers();
  const { isLoading: isLoadingCount, data: subscribersCount } = useGetDeliverySubscribersCount(delivery?.id);

  const { totalPages, page, setPage, limit, offset } = usePagination(10, subscribersCount?.total);

  const { isLoading, data: deliverySubscribers } = useGetDeliverySubscribers(delivery?.id, limit, offset);

  const pagination = { totalPages, page, setPage, limit, offset };

  return (
    <Recipients
      exportMutation={exportDeliverySubscribers}
      delivery={delivery}
      subscribersCount={subscribersCount}
      deliverySubscribers={deliverySubscribers}
      isLoading={isLoadingCount || isLoading || isLoadingDraft}
      pagination={pagination}
    >
    </Recipients>
  );
};

export default RecipientsWrapper;

export function Recipients({ exportMutation, delivery, subscribersCount, deliverySubscribers, isLoading, pagination }) {
  const appState = AppState.useContainer();

  const { totalPages, page, setPage } = pagination;

  const [subscribers, _setSubscribers] = useState([]);

  const timeZone = getClientTimezone();
  function setSubscribers(d) {
    if (d?.length) {
      _setSubscribers(
        d.map(i => ({
          id: i.id,
          email: (
            <Link
              // to={`/subscriber/${i.subscriberId}`} # to-do see if we want to integrate subscribers
              to={'#'}
              style={{
                textDecoration: 'none',
                color: 'black',
              }}
            >
              {i.email}
            </Link>
          ),
          sentToSGAt: formatDateToUserTimezone(i.sentToSGAt, timeZone),
        })),
      );
    }
  }

  useEffect(() => {
    if (appState.signupAndLogin.email === demoEmail) {
      console.log(deliverySubscribers);
      return setSubscribers([...fakeRecipients, ...fakeRecipients, ...fakeRecipients]);
    }

    setSubscribers(deliverySubscribers);
  }, [deliverySubscribers]);

  if (isLoading) {
    return (
      <Box>
        <ScrollArea style={{ height: 'calc(80vh - 200px)' }}>
          <MBTableSkeleton title={'Recipients'} manySelects={0} checkbox rows={[{ label: 'Email' }, { label: 'Sent At' }]} />;
        </ScrollArea>
      </Box>
    );
  }

  return (
    <Box>
      <ScrollArea>
        <Title order={2} m={16}>{appState.signupAndLogin.email === demoEmail ? 113656 : delivery?.sendTo} Recipients</Title>
        {subscribers.length
          ? (
            <MailberryTable
              headers={['Subscriber']}
              tableData={subscribers.map(s => ({ email: s.email }))}
              total={subscribersCount?.total}
            />
          )
          : null}

        {!subscribers.length && <MBEmptySubscribers />}
        <Space h={24} />
        <Group justify='center'>
          <Pagination display={totalPages > 1 ? 'flex' : 'none'} value={page} onChange={setPage} total={totalPages} defaultValue={1} color='mbblue' />
        </Group>
      </ScrollArea>
    </Box>
  );
}

// const useStyles = createStyles(theme => ({
//   rowSelected: {
//     backgroundColor: theme.colorScheme === 'dark' ? theme.fn.rgba(theme.colors[theme.primaryColor][7], 0.2) : theme.colors[theme.primaryColor][0],
//   },
// }));

export function shuffleArray(array) {
  const result = array.slice(); // Hace una copia del arreglo original.

  for (let i = result.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1)); // Selecciona un índice aleatorio.
    [result[i], result[j]] = [result[j], result[i]]; // Intercambia los elementos.
  }

  return result;
}

export const fakeRecipients = [
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWS93H',
    'email': 'lucaszaratedev@gmail.com',
    'sentToSGAt': '2023-03-20T12:00:00Z',
    'unsubscribedAt': '2023-03-20T14:23:15Z',
    'openedAt': '2023-03-20T12:15:32Z',
    'clickedAt': '2023-03-20T12:16:45Z',
    'createdAt': '2023-03-20T11:55:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWS94H',
    'email': 'maria.rodriguez@hotmail.com',
    'sentToSGAt': '2023-03-20T12:05:00Z',
    'unsubscribedAt': '2023-03-21T09:30:00Z',
    'openedAt': '2023-03-20T12:45:20Z',
    'clickedAt': '2023-03-20T12:46:10Z',
    'createdAt': '2023-03-20T12:00:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWS95H',
    'email': 'john.smith@yahoo.com',
    'sentToSGAt': '2023-03-20T12:10:00Z',
    'unsubscribedAt': '2023-03-22T16:45:30Z',
    'openedAt': '2023-03-20T13:20:15Z',
    'clickedAt': '2023-03-20T13:22:00Z',
    'createdAt': '2023-03-20T12:05:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWS96H',
    'email': 'sarah.jones@outlook.com',
    'sentToSGAt': '2023-03-20T12:15:00Z',
    'unsubscribedAt': '2023-03-23T11:15:45Z',
    'openedAt': '2023-03-20T14:00:00Z',
    'clickedAt': '2023-03-20T14:05:30Z',
    'createdAt': '2023-03-20T12:10:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWS97H',
    'email': 'david.wilson@gmail.com',
    'sentToSGAt': '2023-03-20T12:20:00Z',
    'unsubscribedAt': '2023-03-24T08:30:20Z',
    'openedAt': '2023-03-20T15:10:45Z',
    'clickedAt': '2023-03-20T15:12:00Z',
    'createdAt': '2023-03-20T12:15:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWS98H',
    'email': 'emma.brown@yahoo.com',
    'sentToSGAt': '2023-03-20T12:25:00Z',
    'unsubscribedAt': '2023-03-25T13:45:10Z',
    'openedAt': '2023-03-20T16:30:00Z',
    'clickedAt': '2023-03-20T16:32:15Z',
    'createdAt': '2023-03-20T12:20:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWS99H',
    'email': 'michael.taylor@hotmail.com',
    'sentToSGAt': '2023-03-20T12:30:00Z',
    'unsubscribedAt': '2023-03-26T10:20:30Z',
    'openedAt': '2023-03-20T17:45:20Z',
    'clickedAt': '2023-03-20T17:47:00Z',
    'createdAt': '2023-03-20T12:25:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT00H',
    'email': 'olivia.anderson@gmail.com',
    'sentToSGAt': '2023-03-20T12:35:00Z',
    'unsubscribedAt': '2023-03-27T15:10:45Z',
    'openedAt': '2023-03-20T18:15:30Z',
    'clickedAt': '2023-03-20T18:17:45Z',
    'createdAt': '2023-03-20T12:30:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT01H',
    'email': 'william.martin@outlook.com',
    'sentToSGAt': '2023-03-20T12:40:00Z',
    'unsubscribedAt': '2023-03-28T09:30:15Z',
    'openedAt': '2023-03-20T19:00:00Z',
    'clickedAt': '2023-03-20T19:02:30Z',
    'createdAt': '2023-03-20T12:35:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT02H',
    'email': 'sophia.white@yahoo.com',
    'sentToSGAt': '2023-03-20T12:45:00Z',
    'unsubscribedAt': '2023-03-29T14:20:40Z',
    'openedAt': '2023-03-20T20:30:15Z',
    'clickedAt': '2023-03-20T20:32:00Z',
    'createdAt': '2023-03-20T12:40:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT03H',
    'email': 'james.clark@gmail.com',
    'sentToSGAt': '2023-03-20T12:50:00Z',
    'unsubscribedAt': '2023-03-30T07:25:30Z',
    'openedAt': '2023-03-20T21:50:00Z',
    'clickedAt': '2023-03-20T21:55:10Z',
    'createdAt': '2023-03-20T12:45:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT04H',
    'email': 'ava.martinez@outlook.com',
    'sentToSGAt': '2023-03-20T12:55:00Z',
    'unsubscribedAt': '2023-03-31T10:45:15Z',
    'openedAt': '2023-03-20T22:45:30Z',
    'clickedAt': '2023-03-20T22:50:45Z',
    'createdAt': '2023-03-20T12:50:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT05H',
    'email': 'charlotte.thomas@yahoo.com',
    'sentToSGAt': '2023-03-20T13:00:00Z',
    'unsubscribedAt': '2023-04-01T08:50:45Z',
    'openedAt': '2023-03-20T23:15:20Z',
    'clickedAt': '2023-03-20T23:17:30Z',
    'createdAt': '2023-03-20T12:55:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT06H',
    'email': 'elijah.moore@gmail.com',
    'sentToSGAt': '2023-03-20T13:05:00Z',
    'unsubscribedAt': '2023-04-02T16:30:00Z',
    'openedAt': '2023-03-21T00:20:00Z',
    'clickedAt': '2023-03-21T00:22:45Z',
    'createdAt': '2023-03-20T13:00:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT07H',
    'email': 'mia.perez@outlook.com',
    'sentToSGAt': '2023-03-20T13:10:00Z',
    'unsubscribedAt': '2023-04-03T11:10:15Z',
    'openedAt': '2023-03-21T01:45:30Z',
    'clickedAt': '2023-03-21T01:48:15Z',
    'createdAt': '2023-03-20T13:05:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT08H',
    'email': 'ethan.harris@yahoo.com',
    'sentToSGAt': '2023-03-20T13:15:00Z',
    'unsubscribedAt': '2023-04-04T13:55:45Z',
    'openedAt': '2023-03-21T02:50:25Z',
    'clickedAt': '2023-03-21T02:52:30Z',
    'createdAt': '2023-03-20T13:10:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT09H',
    'email': 'isabella.turner@gmail.com',
    'sentToSGAt': '2023-03-20T13:20:00Z',
    'unsubscribedAt': '2023-04-05T10:10:50Z',
    'openedAt': '2023-03-21T03:10:15Z',
    'clickedAt': '2023-03-21T03:15:00Z',
    'createdAt': '2023-03-20T13:15:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT10H',
    'email': 'alexander.lee@outlook.com',
    'sentToSGAt': '2023-03-20T13:25:00Z',
    'unsubscribedAt': '2023-04-06T14:20:30Z',
    'openedAt': '2023-03-21T04:15:00Z',
    'clickedAt': '2023-03-21T04:18:45Z',
    'createdAt': '2023-03-20T13:20:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT11H',
    'email': 'abigail.walker@yahoo.com',
    'sentToSGAt': '2023-03-20T13:30:00Z',
    'unsubscribedAt': '2023-04-07T11:35:15Z',
    'openedAt': '2023-03-21T05:20:25Z',
    'clickedAt': '2023-03-21T05:24:15Z',
    'createdAt': '2023-03-20T13:25:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT12H',
    'email': 'mason.phillips@gmail.com',
    'sentToSGAt': '2023-03-20T13:35:00Z',
    'unsubscribedAt': '2023-04-08T15:40:45Z',
    'openedAt': '2023-03-21T06:35:50Z',
    'clickedAt': '2023-03-21T06:40:30Z',
    'createdAt': '2023-03-20T13:30:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT13H',
    'email': 'emily.evans@outlook.com',
    'sentToSGAt': '2023-03-20T13:40:00Z',
    'unsubscribedAt': '2023-04-09T13:55:20Z',
    'openedAt': '2023-03-21T07:55:15Z',
    'clickedAt': '2023-03-21T07:59:50Z',
    'createdAt': '2023-03-20T13:35:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT14H',
    'email': 'aiden.collins@gmail.com',
    'sentToSGAt': '2023-03-20T13:45:00Z',
    'unsubscribedAt': '2023-04-10T10:10:10Z',
    'openedAt': '2023-03-21T08:30:00Z',
    'clickedAt': '2023-03-21T08:34:15Z',
    'createdAt': '2023-03-20T13:40:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT15H',
    'email': 'amelia.davis@yahoo.com',
    'sentToSGAt': '2023-03-20T13:50:00Z',
    'unsubscribedAt': '2023-04-11T16:25:45Z',
    'openedAt': '2023-03-21T09:45:20Z',
    'clickedAt': '2023-03-21T09:50:30Z',
    'createdAt': '2023-03-20T13:45:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT16H',
    'email': 'logan.bailey@outlook.com',
    'sentToSGAt': '2023-03-20T13:55:00Z',
    'unsubscribedAt': '2023-04-12T14:35:25Z',
    'openedAt': '2023-03-21T10:50:45Z',
    'clickedAt': '2023-03-21T10:55:20Z',
    'createdAt': '2023-03-20T13:50:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT17H',
    'email': 'harper.king@gmail.com',
    'sentToSGAt': '2023-03-20T14:00:00Z',
    'unsubscribedAt': '2023-04-13T10:55:10Z',
    'openedAt': '2023-03-21T12:10:35Z',
    'clickedAt': '2023-03-21T12:15:50Z',
    'createdAt': '2023-03-20T13:55:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT18H',
    'email': 'ella.sanders@yahoo.com',
    'sentToSGAt': '2023-03-20T14:05:00Z',
    'unsubscribedAt': '2023-04-14T15:15:30Z',
    'openedAt': '2023-03-21T13:25:45Z',
    'clickedAt': '2023-03-21T13:30:20Z',
    'createdAt': '2023-03-20T14:00:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT19H',
    'email': 'jackson.brooks@outlook.com',
    'sentToSGAt': '2023-03-20T14:10:00Z',
    'unsubscribedAt': '2023-04-15T12:35:40Z',
    'openedAt': '2023-03-21T14:45:50Z',
    'clickedAt': '2023-03-21T14:49:15Z',
    'createdAt': '2023-03-20T14:05:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT20H',
    'email': 'charlie.stewart@gmail.com',
    'sentToSGAt': '2023-03-20T14:15:00Z',
    'unsubscribedAt': '2023-04-16T09:40:25Z',
    'openedAt': '2023-03-21T16:05:35Z',
    'clickedAt': '2023-03-21T16:10:00Z',
    'createdAt': '2023-03-20T14:10:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT21H',
    'email': 'sofia.morgan@yahoo.com',
    'sentToSGAt': '2023-03-20T14:20:00Z',
    'unsubscribedAt': '2023-04-17T13:55:15Z',
    'openedAt': '2023-03-21T17:20:40Z',
    'clickedAt': '2023-03-21T17:25:25Z',
    'createdAt': '2023-03-20T14:15:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT22H',
    'email': 'lucas.cooper@outlook.com',
    'sentToSGAt': '2023-03-20T14:25:00Z',
    'unsubscribedAt': '2023-04-18T10:10:50Z',
    'openedAt': '2023-03-21T18:30:20Z',
    'clickedAt': '2023-03-21T18:32:45Z',
    'createdAt': '2023-03-20T14:20:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT23H',
    'email': 'livia.washington@gmail.com',
    'sentToSGAt': '2023-03-20T14:30:00Z',
    'unsubscribedAt': '2023-04-19T12:25:30Z',
    'openedAt': '2023-03-21T19:45:15Z',
    'clickedAt': '2023-03-21T19:48:00Z',
    'createdAt': '2023-03-20T14:25:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT24H',
    'email': 'nathan.cole@yahoo.com',
    'sentToSGAt': '2023-03-20T14:35:00Z',
    'unsubscribedAt': '2023-04-20T11:20:10Z',
    'openedAt': '2023-03-21T21:05:25Z',
    'clickedAt': '2023-03-21T21:08:45Z',
    'createdAt': '2023-03-20T14:30:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT25H',
    'email': 'zoey.barnes@outlook.com',
    'sentToSGAt': '2023-03-20T14:40:00Z',
    'unsubscribedAt': '2023-04-21T09:30:50Z',
    'openedAt': '2023-03-21T22:20:00Z',
    'clickedAt': '2023-03-21T22:24:10Z',
    'createdAt': '2023-03-20T14:35:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT26H',
    'email': 'liam.fisher@gmail.com',
    'sentToSGAt': '2023-03-20T14:45:00Z',
    'unsubscribedAt': '2023-04-22T12:40:45Z',
    'openedAt': '2023-03-21T23:35:35Z',
    'clickedAt': '2023-03-21T23:37:50Z',
    'createdAt': '2023-03-20T14:40:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT27H',
    'email': 'ava.reed@yahoo.com',
    'sentToSGAt': '2023-03-20T14:50:00Z',
    'unsubscribedAt': '2023-04-23T13:55:25Z',
    'openedAt': '2023-03-22T00:50:15Z',
    'clickedAt': '2023-03-22T00:52:30Z',
    'createdAt': '2023-03-20T14:45:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT28H',
    'email': 'oliver.butler@outlook.com',
    'sentToSGAt': '2023-03-20T14:55:00Z',
    'unsubscribedAt': '2023-04-24T11:10:35Z',
    'openedAt': '2023-03-22T02:00:25Z',
    'clickedAt': '2023-03-22T02:05:10Z',
    'createdAt': '2023-03-20T14:50:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT29H',
    'email': 'mia.ward@gmail.com',
    'sentToSGAt': '2023-03-20T15:00:00Z',
    'unsubscribedAt': '2023-04-25T12:45:15Z',
    'openedAt': '2023-03-22T03:15:20Z',
    'clickedAt': '2023-03-22T03:16:45Z',
    'createdAt': '2023-03-20T14:55:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT30H',
    'email': 'harper.morris@yahoo.com',
    'sentToSGAt': '2023-03-20T15:05:00Z',
    'unsubscribedAt': '2023-04-26T15:30:10Z',
    'openedAt': '2023-03-22T04:25:35Z',
    'clickedAt': '2023-03-22T04:28:25Z',
    'createdAt': '2023-03-20T15:00:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT31H',
    'email': 'charlie.james@outlook.com',
    'sentToSGAt': '2023-03-20T15:10:00Z',
    'unsubscribedAt': '2023-04-27T13:15:45Z',
    'openedAt': '2023-03-22T05:35:00Z',
    'clickedAt': '2023-03-22T05:37:45Z',
    'createdAt': '2023-03-20T15:05:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT32H',
    'email': 'sofia.carter@gmail.com',
    'sentToSGAt': '2023-03-20T15:15:00Z',
    'unsubscribedAt': '2023-04-28T10:20:30Z',
    'openedAt': '2023-03-22T06:50:25Z',
    'clickedAt': '2023-03-22T06:52:15Z',
    'createdAt': '2023-03-20T15:10:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT33H',
    'email': 'ethan.bennett@yahoo.com',
    'sentToSGAt': '2023-03-20T15:20:00Z',
    'unsubscribedAt': '2023-04-29T14:10:45Z',
    'openedAt': '2023-03-22T07:15:10Z',
    'clickedAt': '2023-03-22T07:20:00Z',
    'createdAt': '2023-03-20T15:15:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT34H',
    'email': 'jayden.wright@outlook.com',
    'sentToSGAt': '2023-03-20T15:25:00Z',
    'unsubscribedAt': '2023-04-30T09:45:15Z',
    'openedAt': '2023-03-22T08:30:35Z',
    'clickedAt': '2023-03-22T08:35:20Z',
    'createdAt': '2023-03-20T15:20:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT35H',
    'email': 'lily.green@gmail.com',
    'sentToSGAt': '2023-03-20T15:30:00Z',
    'unsubscribedAt': '2023-05-01T11:10:10Z',
    'openedAt': '2023-03-22T10:45:40Z',
    'clickedAt': '2023-03-22T10:49:15Z',
    'createdAt': '2023-03-20T15:25:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT36H',
    'email': 'grace.clarkson@yahoo.com',
    'sentToSGAt': '2023-03-20T15:35:00Z',
    'unsubscribedAt': '2023-05-02T12:55:35Z',
    'openedAt': '2023-03-22T12:05:15Z',
    'clickedAt': '2023-03-22T12:10:20Z',
    'createdAt': '2023-03-20T15:30:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT37H',
    'email': 'jacob.hughes@outlook.com',
    'sentToSGAt': '2023-03-20T15:40:00Z',
    'unsubscribedAt': '2023-05-03T11:30:25Z',
    'openedAt': '2023-03-22T13:20:10Z',
    'clickedAt': '2023-03-22T13:23:05Z',
    'createdAt': '2023-03-20T15:35:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT38H',
    'email': 'scarlett.rose@gmail.com',
    'sentToSGAt': '2023-03-20T15:45:00Z',
    'unsubscribedAt': '2023-05-04T15:45:05Z',
    'openedAt': '2023-03-22T14:10:35Z',
    'clickedAt': '2023-03-22T14:13:00Z',
    'createdAt': '2023-03-20T15:40:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT39H',
    'email': 'matthew.scott@outlook.com',
    'sentToSGAt': '2023-03-20T15:50:00Z',
    'unsubscribedAt': '2023-05-05T09:55:25Z',
    'openedAt': '2023-03-22T15:25:15Z',
    'clickedAt': '2023-03-22T15:30:10Z',
    'createdAt': '2023-03-20T15:45:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT40H',
    'email': 'percy.patterson@yahoo.com',
    'sentToSGAt': '2023-03-20T15:55:00Z',
    'unsubscribedAt': '2023-05-06T12:45:35Z',
    'openedAt': '2023-03-22T16:35:05Z',
    'clickedAt': '2023-03-22T16:37:40Z',
    'createdAt': '2023-03-20T15:50:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT41H',
    'email': 'joey.ramirez@outlook.com',
    'sentToSGAt': '2023-03-20T16:00:00Z',
    'unsubscribedAt': '2023-05-07T11:20:10Z',
    'openedAt': '2023-03-22T17:50:00Z',
    'clickedAt': '2023-03-22T17:55:05Z',
    'createdAt': '2023-03-20T15:55:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT42H',
    'email': 'claire.phillips@gmail.com',
    'sentToSGAt': '2023-03-20T16:05:00Z',
    'unsubscribedAt': '2023-05-08T10:40:15Z',
    'openedAt': '2023-03-22T19:00:20Z',
    'clickedAt': '2023-03-22T19:05:10Z',
    'createdAt': '2023-03-20T16:00:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT43H',
    'email': 'dylan.hart@yahoo.com',
    'sentToSGAt': '2023-03-20T16:10:00Z',
    'unsubscribedAt': '2023-05-09T16:25:10Z',
    'openedAt': '2023-03-22T20:10:05Z',
    'clickedAt': '2023-03-22T20:12:40Z',
    'createdAt': '2023-03-20T16:05:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT44H',
    'email': 'anna.young@outlook.com',
    'sentToSGAt': '2023-03-20T16:15:00Z',
    'unsubscribedAt': '2023-05-10T13:30:25Z',
    'openedAt': '2023-03-22T21:35:25Z',
    'clickedAt': '2023-03-22T21:40:30Z',
    'createdAt': '2023-03-20T16:10:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT45H',
    'email': 'michael.freeman@gmail.com',
    'sentToSGAt': '2023-03-20T16:20:00Z',
    'unsubscribedAt': '2023-05-11T11:15:50Z',
    'openedAt': '2023-03-22T22:45:05Z',
    'clickedAt': '2023-03-22T22:48:00Z',
    'createdAt': '2023-03-20T16:15:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT46H',
    'email': 'karen.mills@yahoo.com',
    'sentToSGAt': '2023-03-20T16:25:00Z',
    'unsubscribedAt': '2023-05-12T12:35:50Z',
    'openedAt': '2023-03-22T23:55:10Z',
    'clickedAt': '2023-03-22T23:59:05Z',
    'createdAt': '2023-03-20T16:20:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT47H',
    'email': 'jessie.parker@outlook.com',
    'sentToSGAt': '2023-03-20T16:30:00Z',
    'unsubscribedAt': '2023-05-13T10:20:40Z',
    'openedAt': '2023-03-23T00:15:05Z',
    'clickedAt': '2023-03-23T00:18:35Z',
    'createdAt': '2023-03-20T16:25:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT48H',
    'email': 'derek.duncan@gmail.com',
    'sentToSGAt': '2023-03-20T16:35:00Z',
    'unsubscribedAt': '2023-05-14T12:25:35Z',
    'openedAt': '2023-03-23T01:30:20Z',
    'clickedAt': '2023-03-23T01:35:10Z',
    'createdAt': '2023-03-20T16:30:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT49H',
    'email': 'brynn.evans@yahoo.com',
    'sentToSGAt': '2023-03-20T16:40:00Z',
    'unsubscribedAt': '2023-05-15T17:40:20Z',
    'openedAt': '2023-03-23T02:45:35Z',
    'clickedAt': '2023-03-23T02:50:30Z',
    'createdAt': '2023-03-20T16:35:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT50H',
    'email': 'alex.molina@outlook.com',
    'sentToSGAt': '2023-03-20T16:45:00Z',
    'unsubscribedAt': '2023-05-16T14:55:15Z',
    'openedAt': '2023-03-23T04:15:15Z',
    'clickedAt': '2023-03-23T04:18:45Z',
    'createdAt': '2023-03-20T16:40:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT51H',
    'email': 'maya.lopez@gmail.com',
    'sentToSGAt': '2023-03-20T16:50:00Z',
    'unsubscribedAt': '2023-05-17T16:15:30Z',
    'openedAt': '2023-03-23T05:35:05Z',
    'clickedAt': '2023-03-23T05:40:20Z',
    'createdAt': '2023-03-20T16:45:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT52H',
    'email': 'eric.peterson@yahoo.com',
    'sentToSGAt': '2023-03-20T16:55:00Z',
    'unsubscribedAt': '2023-05-18T10:30:45Z',
    'openedAt': '2023-03-23T07:05:30Z',
    'clickedAt': '2023-03-23T07:10:25Z',
    'createdAt': '2023-03-20T16:50:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT53H',
    'email': 'hanna.kelly@outlook.com',
    'sentToSGAt': '2023-03-20T17:00:00Z',
    'unsubscribedAt': '2023-05-19T14:50:20Z',
    'openedAt': '2023-03-23T08:25:40Z',
    'clickedAt': '2023-03-23T08:30:00Z',
    'createdAt': '2023-03-20T16:55:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT54H',
    'email': 'omar.lin@gmail.com',
    'sentToSGAt': '2023-03-20T17:05:00Z',
    'unsubscribedAt': '2023-05-20T09:20:40Z',
    'openedAt': '2023-03-23T09:30:45Z',
    'clickedAt': '2023-03-23T09:35:30Z',
    'createdAt': '2023-03-20T17:00:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT55H',
    'email': 'isabella.velez@yahoo.com',
    'sentToSGAt': '2023-03-20T17:10:00Z',
    'unsubscribedAt': '2023-05-21T16:35:15Z',
    'openedAt': '2023-03-23T12:45:25Z',
    'clickedAt': '2023-03-23T12:50:00Z',
    'createdAt': '2023-03-20T17:05:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT56H',
    'email': 'larissa.bell@outlook.com',
    'sentToSGAt': '2023-03-20T17:15:00Z',
    'unsubscribedAt': '2023-05-22T11:15:00Z',
    'openedAt': '2023-03-23T13:50:05Z',
    'clickedAt': '2023-03-23T13:55:10Z',
    'createdAt': '2023-03-20T17:10:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT57H',
    'email': 'jack.porter@gmail.com',
    'sentToSGAt': '2023-03-20T17:20:00Z',
    'unsubscribedAt': '2023-05-23T10:30:35Z',
    'openedAt': '2023-03-23T14:55:30Z',
    'clickedAt': '2023-03-23T15:00:00Z',
    'createdAt': '2023-03-20T17:15:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT58H',
    'email': 'helen.morgan@yahoo.com',
    'sentToSGAt': '2023-03-20T17:25:00Z',
    'unsubscribedAt': '2023-05-24T17:40:05Z',
    'openedAt': '2023-03-23T16:05:20Z',
    'clickedAt': '2023-03-23T16:10:30Z',
    'createdAt': '2023-03-20T17:20:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT59H',
    'email': 'peter.baker@outlook.com',
    'sentToSGAt': '2023-03-20T17:30:00Z',
    'unsubscribedAt': '2023-05-25T12:35:45Z',
    'openedAt': '2023-03-23T17:15:00Z',
    'clickedAt': '2023-03-23T17:19:00Z',
    'createdAt': '2023-03-20T17:25:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT60H',
    'email': 'jessica.adams@gmail.com',
    'sentToSGAt': '2023-03-20T17:35:00Z',
    'unsubscribedAt': '2023-05-26T14:40:50Z',
    'openedAt': '2023-03-23T19:25:25Z',
    'clickedAt': '2023-03-23T19:28:10Z',
    'createdAt': '2023-03-20T17:30:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT61H',
    'email': 'marcus.white@yahoo.com',
    'sentToSGAt': '2023-03-20T17:40:00Z',
    'unsubscribedAt': '2023-05-27T10:15:30Z',
    'openedAt': '2023-03-23T21:35:50Z',
    'clickedAt': '2023-03-23T21:40:25Z',
    'createdAt': '2023-03-20T17:35:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT62H',
    'email': 'catherine.morris@outlook.com',
    'sentToSGAt': '2023-03-20T17:45:00Z',
    'unsubscribedAt': '2023-05-28T11:35:40Z',
    'openedAt': '2023-03-23T23:45:30Z',
    'clickedAt': '2023-03-23T23:49:20Z',
    'createdAt': '2023-03-20T17:40:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT63H',
    'email': 'nash.stone@gmail.com',
    'sentToSGAt': '2023-03-20T17:50:00Z',
    'unsubscribedAt': '2023-05-29T16:20:25Z',
    'openedAt': '2023-03-24T00:55:10Z',
    'clickedAt': '2023-03-24T00:58:50Z',
    'createdAt': '2023-03-20T17:45:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT64H',
    'email': 'bianca.ellis@yahoo.com',
    'sentToSGAt': '2023-03-20T17:55:00Z',
    'unsubscribedAt': '2023-05-30T12:50:05Z',
    'openedAt': '2023-03-24T02:15:35Z',
    'clickedAt': '2023-03-24T02:19:35Z',
    'createdAt': '2023-03-20T17:50:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT65H',
    'email': 'leo.brown@outlook.com',
    'sentToSGAt': '2023-03-20T18:00:00Z',
    'unsubscribedAt': '2023-05-31T12:20:30Z',
    'openedAt': '2023-03-24T03:30:25Z',
    'clickedAt': '2023-03-24T03:33:15Z',
    'createdAt': '2023-03-20T17:55:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT66H',
    'email': 'jasmine.lewis@gmail.com',
    'sentToSGAt': '2023-03-20T18:05:00Z',
    'unsubscribedAt': '2023-06-01T15:10:20Z',
    'openedAt': '2023-03-24T04:50:00Z',
    'clickedAt': '2023-03-24T04:55:25Z',
    'createdAt': '2023-03-20T18:00:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT67H',
    'email': 'lucas.mason@yahoo.com',
    'sentToSGAt': '2023-03-20T18:10:00Z',
    'unsubscribedAt': '2023-06-02T13:35:35Z',
    'openedAt': '2023-03-24T06:10:40Z',
    'clickedAt': '2023-03-24T06:15:00Z',
    'createdAt': '2023-03-20T18:05:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT68H',
    'email': 'naomi.king@outlook.com',
    'sentToSGAt': '2023-03-20T18:15:00Z',
    'unsubscribedAt': '2023-06-03T11:25:50Z',
    'openedAt': '2023-03-24T07:15:15Z',
    'clickedAt': '2023-03-24T07:19:40Z',
    'createdAt': '2023-03-20T18:10:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT69H',
    'email': 'michael.clarke@gmail.com',
    'sentToSGAt': '2023-03-20T18:20:00Z',
    'unsubscribedAt': '2023-06-04T17:40:45Z',
    'openedAt': '2023-03-24T08:30:25Z',
    'clickedAt': '2023-03-24T08:33:20Z',
    'createdAt': '2023-03-20T18:15:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT70H',
    'email': 'victor.hall@yahoo.com',
    'sentToSGAt': '2023-03-20T18:25:00Z',
    'unsubscribedAt': '2023-06-05T13:20:15Z',
    'openedAt': '2023-03-24T09:45:50Z',
    'clickedAt': '2023-03-24T09:49:25Z',
    'createdAt': '2023-03-20T18:20:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT71H',
    'email': 'evangeline.bishop@outlook.com',
    'sentToSGAt': '2023-03-20T18:30:00Z',
    'unsubscribedAt': '2023-06-06T14:35:30Z',
    'openedAt': '2023-03-24T11:05:40Z',
    'clickedAt': '2023-03-24T11:10:00Z',
    'createdAt': '2023-03-20T18:25:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT72H',
    'email': 'hugh.lewis@gmail.com',
    'sentToSGAt': '2023-03-20T18:35:00Z',
    'unsubscribedAt': '2023-06-07T15:40:20Z',
    'openedAt': '2023-03-24T12:30:10Z',
    'clickedAt': '2023-03-24T12:34:25Z',
    'createdAt': '2023-03-20T18:30:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT73H',
    'email': 'eliza.cruise@yahoo.com',
    'sentToSGAt': '2023-03-20T18:40:00Z',
    'unsubscribedAt': '2023-06-08T11:10:30Z',
    'openedAt': '2023-03-24T13:45:20Z',
    'clickedAt': '2023-03-24T13:49:25Z',
    'createdAt': '2023-03-20T18:35:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT74H',
    'email': 'steve.jones@outlook.com',
    'sentToSGAt': '2023-03-20T18:45:00Z',
    'unsubscribedAt': '2023-06-09T16:20:45Z',
    'openedAt': '2023-03-24T15:10:15Z',
    'clickedAt': '2023-03-24T15:15:05Z',
    'createdAt': '2023-03-20T18:40:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT75H',
    'email': 'opal.hughes@gmail.com',
    'sentToSGAt': '2023-03-20T18:50:00Z',
    'unsubscribedAt': '2023-06-10T13:30:15Z',
    'openedAt': '2023-03-24T17:30:50Z',
    'clickedAt': '2023-03-24T17:34:40Z',
    'createdAt': '2023-03-20T18:45:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT76H',
    'email': 'nora.morris@yahoo.com',
    'sentToSGAt': '2023-03-20T18:55:00Z',
    'unsubscribedAt': '2023-06-11T14:45:25Z',
    'openedAt': '2023-03-24T19:10:45Z',
    'clickedAt': '2023-03-24T19:15:00Z',
    'createdAt': '2023-03-20T18:50:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT77H',
    'email': 'richard.scott@outlook.com',
    'sentToSGAt': '2023-03-20T19:00:00Z',
    'unsubscribedAt': '2023-06-12T15:40:35Z',
    'openedAt': '2023-03-24T20:25:00Z',
    'clickedAt': '2023-03-24T20:29:45Z',
    'createdAt': '2023-03-20T18:55:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT78H',
    'email': 'selena.martin@gmail.com',
    'sentToSGAt': '2023-03-20T19:05:00Z',
    'unsubscribedAt': '2023-06-13T11:50:15Z',
    'openedAt': '2023-03-24T21:45:30Z',
    'clickedAt': '2023-03-24T21:50:25Z',
    'createdAt': '2023-03-20T19:00:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT79H',
    'email': 'ella.wright@yahoo.com',
    'sentToSGAt': '2023-03-20T19:10:00Z',
    'unsubscribedAt': '2023-06-14T12:45:30Z',
    'openedAt': '2023-03-24T23:15:05Z',
    'clickedAt': '2023-03-24T23:20:05Z',
    'createdAt': '2023-03-20T19:05:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT80H',
    'email': 'frankie.greene@outlook.com',
    'sentToSGAt': '2023-03-20T19:15:00Z',
    'unsubscribedAt': '2023-06-15T16:15:40Z',
    'openedAt': '2023-03-25T00:25:00Z',
    'clickedAt': '2023-03-25T00:29:30Z',
    'createdAt': '2023-03-20T19:10:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT81H',
    'email': 'glen.kennedy@gmail.com',
    'sentToSGAt': '2023-03-20T19:20:00Z',
    'unsubscribedAt': '2023-06-16T11:30:55Z',
    'openedAt': '2023-03-25T01:30:30Z',
    'clickedAt': '2023-03-25T01:36:15Z',
    'createdAt': '2023-03-20T19:15:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT82H',
    'email': 'irma.harmon@yahoo.com',
    'sentToSGAt': '2023-03-20T19:25:00Z',
    'unsubscribedAt': '2023-06-17T12:40:55Z',
    'openedAt': '2023-03-25T02:35:15Z',
    'clickedAt': '2023-03-25T02:38:30Z',
    'createdAt': '2023-03-20T19:20:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT83H',
    'email': 'july.moore@outlook.com',
    'sentToSGAt': '2023-03-20T19:30:00Z',
    'unsubscribedAt': '2023-06-18T17:55:35Z',
    'openedAt': '2023-03-25T04:15:45Z',
    'clickedAt': '2023-03-25T04:20:55Z',
    'createdAt': '2023-03-20T19:25:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT84H',
    'email': 'koen.parker@gmail.com',
    'sentToSGAt': '2023-03-20T19:35:00Z',
    'unsubscribedAt': '2023-06-19T13:30:10Z',
    'openedAt': '2023-03-25T05:40:05Z',
    'clickedAt': '2023-03-25T05:44:20Z',
    'createdAt': '2023-03-20T19:30:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT85H',
    'email': 'lionel.jackson@yahoo.com',
    'sentToSGAt': '2023-03-20T19:40:00Z',
    'unsubscribedAt': '2023-06-20T14:55:55Z',
    'openedAt': '2023-03-25T07:05:30Z',
    'clickedAt': '2023-03-25T07:10:05Z',
    'createdAt': '2023-03-20T19:35:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT86H',
    'email': 'milo.walters@outlook.com',
    'sentToSGAt': '2023-03-20T19:45:00Z',
    'unsubscribedAt': '2023-06-21T10:40:45Z',
    'openedAt': '2023-03-25T08:30:40Z',
    'clickedAt': '2023-03-25T08:34:15Z',
    'createdAt': '2023-03-20T19:40:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT87H',
    'email': 'noah.boone@gmail.com',
    'sentToSGAt': '2023-03-20T19:50:00Z',
    'unsubscribedAt': '2023-06-22T16:45:55Z',
    'openedAt': '2023-03-25T10:05:55Z',
    'clickedAt': '2023-03-25T10:10:50Z',
    'createdAt': '2023-03-20T19:45:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT88H',
    'email': 'olivia.boyd@yahoo.com',
    'sentToSGAt': '2023-03-20T19:55:00Z',
    'unsubscribedAt': '2023-06-23T12:35:05Z',
    'openedAt': '2023-03-25T11:55:10Z',
    'clickedAt': '2023-03-25T12:00:00Z',
    'createdAt': '2023-03-20T19:50:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT89H',
    'email': 'pete.allen@outlook.com',
    'sentToSGAt': '2023-03-20T20:00:00Z',
    'unsubscribedAt': '2023-06-24T15:30:05Z',
    'openedAt': '2023-03-25T13:40:20Z',
    'clickedAt': '2023-03-25T13:45:15Z',
    'createdAt': '2023-03-20T19:55:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT90H',
    'email': 'roger.bolton@gmail.com',
    'sentToSGAt': '2023-03-20T20:05:00Z',
    'unsubscribedAt': '2023-06-25T11:10:30Z',
    'openedAt': '2023-03-25T14:50:05Z',
    'clickedAt': '2023-03-25T14:55:50Z',
    'createdAt': '2023-03-20T20:00:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT91H',
    'email': 'sam.tyler@yahoo.com',
    'sentToSGAt': '2023-03-20T20:10:00Z',
    'unsubscribedAt': '2023-06-26T12:50:15Z',
    'openedAt': '2023-03-25T15:45:00Z',
    'clickedAt': '2023-03-25T15:49:10Z',
    'createdAt': '2023-03-20T20:05:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT92H',
    'email': 'thea.grant@outlook.com',
    'sentToSGAt': '2023-03-20T20:15:00Z',
    'unsubscribedAt': '2023-06-27T14:15:20Z',
    'openedAt': '2023-03-25T17:30:50Z',
    'clickedAt': '2023-03-25T17:35:20Z',
    'createdAt': '2023-03-20T20:10:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT93H',
    'email': 'una.leon@gmail.com',
    'sentToSGAt': '2023-03-20T20:20:00Z',
    'unsubscribedAt': '2023-06-28T11:55:35Z',
    'openedAt': '2023-03-25T18:45:05Z',
    'clickedAt': '2023-03-25T18:50:35Z',
    'createdAt': '2023-03-20T20:15:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT94H',
    'email': 'vinny.payne@outlook.com',
    'sentToSGAt': '2023-03-20T20:25:00Z',
    'unsubscribedAt': '2023-06-29T13:35:05Z',
    'openedAt': '2023-03-25T20:05:00Z',
    'clickedAt': '2023-03-25T20:09:40Z',
    'createdAt': '2023-03-20T20:20:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT95H',
    'email': 'willow.bush@gmail.com',
    'sentToSGAt': '2023-03-20T20:30:00Z',
    'unsubscribedAt': '2023-06-30T16:30:40Z',
    'openedAt': '2023-03-25T21:35:20Z',
    'clickedAt': '2023-03-25T21:39:15Z',
    'createdAt': '2023-03-20T20:25:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT96H',
    'email': 'yara.morales@yahoo.com',
    'sentToSGAt': '2023-03-20T20:35:00Z',
    'unsubscribedAt': '2023-07-01T15:55:50Z',
    'openedAt': '2023-03-25T23:10:45Z',
    'clickedAt': '2023-03-25T23:15:20Z',
    'createdAt': '2023-03-20T20:30:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT97H',
    'email': 'zach.wells@outlook.com',
    'sentToSGAt': '2023-03-20T20:40:00Z',
    'unsubscribedAt': '2023-07-02T12:40:50Z',
    'openedAt': '2023-03-26T00:45:10Z',
    'clickedAt': '2023-03-26T00:50:05Z',
    'createdAt': '2023-03-20T20:35:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT98H',
    'email': 'amelia.chavez@gmail.com',
    'sentToSGAt': '2023-03-20T20:45:00Z',
    'unsubscribedAt': '2023-07-03T13:45:35Z',
    'openedAt': '2023-03-26T02:15:35Z',
    'clickedAt': '2023-03-26T02:20:15Z',
    'createdAt': '2023-03-20T20:40:00Z',
  },
  {
    'id': '01JPWB9GPFCKQBDZ6A6TMWT99H',
    'email': 'bruno.richards@yahoo.com',
    'sentToSGAt': '2023-03-20T20:50:00Z',
    'unsubscribedAt': '2023-07-04T14:30:20Z',
    'openedAt': '2023-03-26T03:40:25Z',
    'clickedAt': '2023-03-26T03:45:00Z',
    'createdAt': '2023-03-20T20:45:00Z',
  },
];
