var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Node, ReactNodeViewRenderer } from '@tiptap/react';
import styleObjectToCss from 'style-object-to-css-string';
import ParagraphView from './ParagraphView';
export var ReactEmailParagraph = Node.create({
    name: 'paragraph',
    group: 'block',
    draggable: true,
    priority: 1000,
    addOptions: function () {
        return {
            HTMLAttributes: {},
        };
    },
    content: 'inline*',
    addAttributes: function () {
        return {
            style: {
                default: '',
            },
            isChildOfListItem: {
                default: false,
            },
        };
    },
    parseHTML: function () {
        return [
            {
                tag: 'react-email-text',
                getAttrs: function (node) {
                    if (!(node instanceof HTMLElement)) {
                        return {};
                    }
                    return {
                        style: node.getAttribute('style'),
                    };
                },
            },
        ];
    },
    renderHTML: function (_a) {
        var node = _a.node, HTMLAttributes = _a.HTMLAttributes;
        var pAttributes = __assign(__assign({}, HTMLAttributes), { style: HTMLAttributes.style || styleObjectToCss({ fontSize: '18px', margin: '8px 0' }) });
        if (HTMLAttributes.isChildOfListItem) {
            return ['react-email-text', pAttributes, 0];
        }
        return ['react-email-section', {
                style: styleObjectToCss({
                    padding: '0 50px',
                    height: '40px'
                }), class: 'node-section'
            }, ['react-email-text', pAttributes, 0]];
    },
    addCommands: function () {
        var _this = this;
        return {
            setParagraph: function () { return function (_a) {
                var commands = _a.commands;
                return commands.setNode(_this.name);
            }; },
        };
    },
    addNodeView: function () {
        return ReactNodeViewRenderer(ParagraphView);
    },
});
