import SequenceEmailLiveCard from '@/components/screens/Sequence/SequenceEmailLiveCard';
import SequenceMetricTriggerCard from '@/components/screens/Sequence/SequenceMetricTriggerCard';
import SequenceSegmentTriggerCard from '@/components/screens/Sequence/SequenceSegmentTriggerCard';
import { DesktopPreviewWrapper, MobileEmailPreviewWithoutActions } from '@/components/shared/EmailPreview';
import { StepsWithDeliveries } from '@/services/api/sequences';
import { SEQUENCE_TYPE, Web } from '@/sharedTypes';
import { Box, Flex, Space, Stack, Text } from '@mantine/core';
import { useNavigate } from 'react-router-dom';

enum VIEW_MODE {
  MOBILE = 'MOBILE',
  DESKTOP = 'DESKTOP',
}

interface LiveSequenceProps {
  steps: StepsWithDeliveries;
  sequence: Web.Response.Sequence & { triggerType: string; };
  sequenceType: SEQUENCE_TYPE;
  segmentName?: string;
}
export const LiveSequenceBase = ({ steps = [], sequence, segmentName, sequenceType }: LiveSequenceProps) => {
  const navigate = useNavigate();

  return (
    <Stack w={'100%'} h={'100%'} mx={20} gap={32} style={{ alignItems: 'center' }}>
      {(sequence.segmentId || sequence.triggerType)
        ? (
          <Flex h={'100%'} gap={0}>
            <Stack miw={400} gap={0}>
              <Space h={40} />
              {sequenceType === SEQUENCE_TYPE.SEGMENT
                ? <SequenceSegmentTriggerCard segmentName={segmentName} segmentId={sequence.segmentId} />
                : <SequenceMetricTriggerCard name={sequence.triggerType} />}
              {steps.map(step => (
                <SequenceEmailLiveCard
                  key={step.id}
                  sequenceStepId={step.id}
                  delayInMinutes={step.delayInMinutes}
                  subject={step.delivery.subject}
                  isEmailSelected={false}
                  // onClick={() => navigate(`/sequence/compose/${sequenceType}/${sequence.id}`, { state: { goToEmailsStep: true, emailSelectedId: step.id } })}
                  onClick={() => {}}
                  delivery={step.delivery}
                  liveMode
                />
              ))}
            </Stack>
          </Flex>
        )
        : (
          <Stack h={'100%'} gap={8}>
            <Text span fz={18} fw={700}>No trigger found</Text>
            <Text>Select a trigger before continuing</Text>
          </Stack>
        )}
    </Stack>
  );
};

const SimpleEmailPreview = ({ appState, viewMode, delivery }) => {
  // const { data, isLoading, isFetching } = useGetSequenceDelivery(sequenceStepId, {
  //   // 2 minutes
  //   cacheTime: 1000 * 60 * 2,
  //   enabled: !!sequenceStepId,
  // });

  return viewMode == VIEW_MODE.DESKTOP
    ? (
      <Box style={{ fontSize: '14px', textAlign: 'left', padding: '3em 16px 0 16px ', backgroundColor: delivery.styles.backgroundColor }}>
        <DesktopPreviewWrapper
          htmlContent={delivery.body}
          appState={appState}
          editor={'TIPTAP'}
          styles={delivery.styles}
        />
      </Box>
    )
    : (
      <MobileEmailPreviewWithoutActions
        htmlContent={delivery.body}
        editor={'TIPTAP'}
        styles={delivery.styles}
      />
    );
};
