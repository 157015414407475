import { HorizontalRule } from '@tiptap/extension-horizontal-rule';
import { ReactNodeViewRenderer } from '@tiptap/react';
import styleObjectToCss from 'style-object-to-css-string';
import DividerView from './DividerView';
export var ReactEmailDivider = HorizontalRule.extend({
    draggable: true,
    parseHTML: function () {
        return [
            {
                tag: 'react-email-hr',
            },
        ];
    },
    addAttributes: function () {
        return {
            'data-hr-styles': {
                default: styleObjectToCss({
                    borderColor: 'rgb(209,213,219)',
                    borderTopWidth: 1,
                    margin: 0,
                }),
            },
            'data-section-styles': {
                default: styleObjectToCss({ padding: '12px 50px' }),
            },
        };
    },
    renderHTML: function (_a) {
        var HTMLAttributes = _a.HTMLAttributes;
        return ['react-email-section', { style: HTMLAttributes['data-section-styles'], class: 'node-section' }, ['react-email-hr', { style: HTMLAttributes['data-hr-styles'] }]];
    },
    addNodeView: function () {
        return ReactNodeViewRenderer(DividerView);
    },
});
