import { Heading } from '@tiptap/extension-heading';
import { ReactNodeViewRenderer } from '@tiptap/react';
import styleObjectToCss from 'style-object-to-css-string';
import HeadingView from './HeadingView';
var HEADING_STYLES = {
    '1': {
        display: 'block',
        // marginTop: '7px',
        // marginBottom: '9px',
        fontSize: '32px',
        lineHeight: '38px',
    },
    '2': {
        display: 'block',
        // marginTop: '6px',
        // marginBottom: '8px',
        fontSize: '24px',
        lineHeight: '30px',
    },
};
export var ReactEmailHeading = Heading.extend({
    draggable: true,
    addAttributes: function () {
        return {
            level: {
                default: 1,
            },
            'data-heading-styles': {
                default: null,
                parseHTML: function (element) {
                    var _a;
                    var level = ((_a = element.getAttribute('as')) === null || _a === void 0 ? void 0 : _a.replace('h', '')) || '1';
                    return styleObjectToCss(HEADING_STYLES[level]);
                },
                renderHTML: function (attributes) {
                    var _a;
                    var level = ((_a = attributes.level) === null || _a === void 0 ? void 0 : _a.toString()) || '1';
                    return { 'data-heading-styles': styleObjectToCss(HEADING_STYLES[level]) };
                },
            },
        };
    },
    parseHTML: function () {
        return [
            {
                tag: 'react-email-heading',
                getAttrs: function (node) {
                    var asAttr = node instanceof HTMLElement ? node.getAttribute('as') : null;
                    // Extract the number from h1, h2, etc.
                    var level = asAttr ? parseInt(asAttr.replace('h', '')) : 1;
                    return { level: level };
                },
            },
        ];
    },
    renderHTML: function (_a) {
        var HTMLAttributes = _a.HTMLAttributes, node = _a.node;
        var level = node.attrs.level;
        return ['react-email-section', { style: styleObjectToCss({ padding: '7px 50px 9px' }), class: 'node-section' }, ['react-email-heading', {
                    style: HTMLAttributes['data-heading-styles'] + (HTMLAttributes['style'] ? HTMLAttributes['style'] : ''),
                    as: "h".concat(level || 1),
                }, 0]];
    },
    addNodeView: function () {
        return ReactNodeViewRenderer(HeadingView);
    },
});
