import { genericAuthRequest, useMailberryQuery } from '@/services/api/base';
import { SUBSCRIBER_STATUS } from '@/sharedTypes';
import { Web } from '@/sharedTypes';

type IGetAllSubscribersRequest = {
  segmentIds: string[];
  status?: SUBSCRIBER_STATUS[];
  limit: number;
  offset: number;
};

export function useGetAllSubscribers(userData: IGetAllSubscribersRequest) {
  return useMailberryQuery<Web.Response.Subscriber[]>({
    f: accessToken => {
      return genericAuthRequest(accessToken, 'get', `/subscriber`, {
        segmentIds: userData.segmentIds,
        status: userData.status,
        limit: userData.limit,
        offset: userData.offset,
      });
    },
    queryKey: [userData.segmentIds.join(''), userData.status?.join(''), userData.limit, userData.offset],
  });
}

type IGetAllSubscribersCountRequest = {
  segmentIds: string[];
  status?: SUBSCRIBER_STATUS[];
};

export function useGetAllSubscribersCount(userData: IGetAllSubscribersCountRequest, options?) {
  return useMailberryQuery<Web.Response.CountTotal>({
    f: accessToken => {
      return genericAuthRequest(accessToken, 'get', `/subscriber/total`, {
        segmentIds: userData.segmentIds,
        status: userData.status,
      });
    },
    options,
    queryKey: [userData?.segmentIds?.join(''), userData?.status?.join('')],
  });
}
