import { Box, Group } from '@mantine/core';
import { useHover } from '@mantine/hooks';
import { NodeViewContent, NodeViewWrapper } from '@tiptap/react';
import React from 'react';
import MBDrag from '@assets/icons/MBDrag';
import { MenuActions } from '@pages/EmailCompose/Common/Tiptap/Menus/MenuActions';
import { Section } from '@react-email/components';
var BulletListView = function (_a) {
    var editor = _a.editor, node = _a.node, getPos = _a.getPos, selected = _a.selected;
    var textAlign = node.attrs.textAlign;
    var _b = useHover(), hovered = _b.hovered, ref = _b.ref;
    var sectionStyles = {
        padding: '0px 50px',
    };
    if (editor.storage.isMobile) {
        sectionStyles.padding = '0 20px';
    }
    return (React.createElement(NodeViewWrapper, { ref: ref, style: {
            display: 'block',
            position: 'relative',
        } },
        React.createElement(Box, { className: 'node-actions' },
            React.createElement(Group, { w: 80, pt: 24, gap: 0, align: 'left', style: {
                    visibility: hovered ? 'visible' : 'hidden',
                } },
                React.createElement(Group, { gap: 8 },
                    React.createElement(Box, { h: 24, style: { cursor: 'pointer' } },
                        React.createElement(MenuActions, { editor: editor, open: null, setOpen: null })),
                    React.createElement(Box, { h: 24, "data-drag-handle": true, style: { cursor: 'grab' } },
                        React.createElement(MBDrag, { size: 24, color: '#A0A0A0' }))))),
        React.createElement(Section, { style: sectionStyles },
            React.createElement(NodeViewContent, { style: { textAlign: textAlign }, as: 'ul' }))));
};
export default BulletListView;
