var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Document } from '@tiptap/extension-document';
import { ReactNodeViewRenderer } from '@tiptap/react';
import styleObjectToCss from 'style-object-to-css-string';
import { DocumentView } from './DocumentView';
import styleToJs from 'style-to-js';
export var ReactEmailDocument = Document.extend({
    group: 'block',
    content: 'block+',
    defining: true,
    addStorage: function () {
        return {
            isMobile: false
        };
    },
    onUpdate: function () {
        this.editor.view.updateState(this.editor.view.state);
    },
    addAttributes: function () {
        return {
            styles: {
                default: {
                    body: {
                        padding: '24px 0px',
                        backgroundColor: '#ffffff',
                    },
                    container: {
                        maxWidth: '760px',
                        fontSize: '18px',
                        padding: '10px 0',
                        backgroundColor: '#ffffff',
                        borderRadius: '12px',
                        border: '2px solid #dddddd',
                        color: '#000000',
                    },
                    section: {},
                },
            },
        };
    },
    parseHTML: function () {
        return [{
                tag: 'react-email-container',
                preserveWhitespace: 'full',
                getAttrs: function (element) {
                    if (!(element instanceof HTMLElement))
                        return {};
                    var bodyElement = element.closest('react-email-body');
                    var bodyStyle = bodyElement === null || bodyElement === void 0 ? void 0 : bodyElement.getAttribute('style');
                    var containerStyle = element.getAttribute('style');
                    var sectionElement = element.querySelector('react-email-section');
                    var sectionStyle = sectionElement === null || sectionElement === void 0 ? void 0 : sectionElement.getAttribute('style');
                    return {
                        styles: {
                            body: styleToJs(bodyStyle),
                            container: styleToJs(containerStyle),
                            section: styleToJs(sectionStyle),
                        }
                    };
                }
            }];
    },
    renderHTML: function (_a) {
        var HTMLAttributes = _a.HTMLAttributes, node = _a.node;
        var styles = node.attrs.styles;
        return [
            'react-email-html',
            {},
            ['react-email-head'],
            ['template-preheader'],
            ['react-email-body', { style: styleObjectToCss(styles.body), class: 'document-body' }, ['react-email-container', { style: styleObjectToCss(styles.container), class: 'document-container' }, ['react-email-section', {
                            style: styleObjectToCss(styles.section),
                        }, 0], ['template-footer']], ['template-created-with']],
        ];
    },
    addKeyboardShortcuts: function () {
        var _this = this;
        return {
            Backspace: function () {
                var state = _this.editor.state;
                var selection = state.selection, doc = state.doc;
                var $anchor = selection.$anchor;
                // Prevent deletion of the document structure
                var isEmpty = state.doc.textContent.length <= 0;
                var isAtStart = $anchor.pos === 2;
                if (isEmpty && isAtStart || isAtStart) {
                    // Prevent deletion and keep at least one empty text node
                    return true;
                }
                // Allow normal backspace behavior in other casess
                return false;
            },
            Enter: function () {
                var _a;
                // Prevent default enter behavior and handle it manually
                return (_a = _this.editor) === null || _a === void 0 ? void 0 : _a.commands.first(function (_a) {
                    var commands = _a.commands;
                    return [
                        function () { return commands.splitBlock(); },
                    ];
                });
            },
        };
    },
    addCommands: function () {
        var _this = this;
        return {
            setHtmlWrapper: function () { return function (_a) {
                var tr = _a.tr, state = _a.state, dispatch = _a.dispatch;
                var doc = state.doc;
                var firstNode = doc.content.firstChild;
                if ((firstNode === null || firstNode === void 0 ? void 0 : firstNode.type.name) === 'doc') {
                    return false;
                }
                var htmlWrapperNode = _this.type.create({}, doc.content);
                var transaction = tr.replaceWith(0, doc.content.size, htmlWrapperNode);
                if (dispatch) {
                    dispatch(transaction);
                }
                return true;
            }; },
            setPreviewText: function (previewText) { return function (_a) {
                var _b;
                var tr = _a.tr, dispatch = _a.dispatch;
                tr.setNodeMarkup(0, undefined, __assign(__assign({}, (_b = tr.doc.firstChild) === null || _b === void 0 ? void 0 : _b.attrs), { previewText: previewText }));
                if (dispatch) {
                    dispatch(tr);
                }
                return true;
            }; },
            updateStyles: function (newStyles) { return function (_a) {
                var _b, _c;
                var tr = _a.tr, dispatch = _a.dispatch;
                var currentStyles = ((_b = tr.doc.firstChild) === null || _b === void 0 ? void 0 : _b.attrs.styles) || {};
                var updatedStyles = __assign(__assign({}, currentStyles), { body: __assign(__assign({}, currentStyles.body), { backgroundColor: newStyles.backgroundColor || currentStyles.body.backgroundColor }), container: __assign(__assign({}, currentStyles.container), { backgroundColor: newStyles.editorColor || currentStyles.container.backgroundColor, borderRadius: (newStyles.borderRadius || currentStyles.container.borderRadius) + 'px', border: newStyles.borderWidth && newStyles.borderColor
                            ? "".concat(newStyles.borderWidth, "px solid ").concat(newStyles.borderColor)
                            : currentStyles.container.border, color: newStyles.fontColor || currentStyles.container.color }), section: currentStyles.section || {} });
                tr.setNodeMarkup(0, undefined, __assign(__assign({}, (_c = tr.doc.firstChild) === null || _c === void 0 ? void 0 : _c.attrs), { styles: updatedStyles }));
                if (dispatch) {
                    dispatch(tr);
                }
                return true;
            }; },
        };
    },
    addNodeView: function () {
        return ReactNodeViewRenderer(DocumentView);
    },
});
