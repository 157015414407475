import MBEmptySubscribers from '@/mailberry/Empty/MBEmptySubscribers';
import MailberryTable from '@/mailberry/MailberryTable';
import { useGetAllSubscribers, useGetAllSubscribersCount } from '@/services/api/subscriber';
import { usePagination } from '@/services/hooks/usePagination';
import { SUBSCRIBER_STATUS } from '@/sharedTypes';
import { Box, Group, Loader, Pagination, ScrollArea, Space, Title } from '@mantine/core';
import { useNavigate, useParams } from 'react-router-dom';

interface Subscriber {
  id: string;
  email: string;
  firstName?: string;
  lastName?: string;
  createdAt: string;
}

interface Segment {
  id: string;
  name: string;
  description?: string;
}

const SegmentSubscribers = () => {
  const { segmentId } = useParams();
  const navigate = useNavigate();
  const { data: subscribersTotal, isLoading: isLoadingTotal } = useGetAllSubscribersCount({
    segmentIds: segmentId ? [segmentId] : [],
    status: [SUBSCRIBER_STATUS.ACTIVE],
  });

  const { totalPages, page, setPage, limit, offset } = usePagination(25, subscribersTotal?.total);

  const { isLoading: isLoadingSubscribers, data: subscribers } = useGetAllSubscribers({
    segmentIds: segmentId ? [segmentId] : [],
    status: [SUBSCRIBER_STATUS.ACTIVE],
    limit: limit,
    offset: offset,
  });

  if (isLoadingSubscribers || isLoadingTotal) {
    return (
      <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
        <Loader />
      </Box>
    );
  }

  return (
    <Box>
      <ScrollArea>
        <Title order={2} m={16}>{subscribersTotal?.total} Subscribers</Title>
        {subscribers?.length
          ? (
            <MailberryTable
              headers={['Subscriber']}
              tableData={subscribers.map(s => ({ email: s.email }))}
              total={subscribersTotal?.total}
            />
          )
          : null}

        {!subscribers?.length && <MBEmptySubscribers />}
        <Space h={24} />
        <Group justify='center'>
          <Pagination display={totalPages > 1 ? 'flex' : 'none'} value={page} onChange={setPage} total={totalPages} defaultValue={1} color='mbblue' />
        </Group>
      </ScrollArea>
    </Box>
  );
};

export default SegmentSubscribers;
